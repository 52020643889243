<template>
  <main class="page apps-page">
    <apps-platform :platform="getOS()"></apps-platform>
    <apps-section />
    <section class="section section--advantages advantages">
      <div>
        <div class="advantages__title">
          <h2 class="title title-lg text-center">
            {{
              $t(`web.why_use`, { productName: $store.state.app.productName })
            }}
          </h2>
        </div>
        <div class="advantages__list">
          <div
            v-for="(item, index) in whyUseItems"
            :key="index"
            class="advantages__item text-center"
          >
            <div class="advantages__item-img">
              <img :src="item.img.src" alt="" />
            </div>
            <div class="advantages__item-title pt-3">
              <span class="text text-sm text-bold">{{ item.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <buy-now-section
      :title="
        $t(`web.download_VPN`, { productName: $store.state.app.productName })
      "
      :description="null"
      :buttonValue="$t(`getVPN`, { productName: $store.state.app.productName })"
    ></buy-now-section>
  </main>
</template>

<script>
import AppsPlatform from './appsPlatform'
import BuyNowSection from '@/components/Sections/BuyNow'
import AppsSection from '@/components/Sections/Apps'
import { PLATFORM } from '@/CONSTANTS'
export default {
  name: 'Apps',
  components: {
    BuyNowSection,
    AppsSection,
    AppsPlatform,
  },
  props: {
    platform: {
      type: String,
      required: true,
    },
  },
  computed: {
    whyUseItems: {
      get() {
        return [
          {
            img: {
              src: require('@/assets/image/apps/icon-1.png'),
            },
            description: this.$t('web.access'),
          },
          {
            img: {
              src: require('@/assets/image/apps/icon-4.png'),
            },
            description: this.$t('web.jurisdiction'),
          },
          {
            img: {
              src: require('@/assets/image/apps/icon-2.png'),
            },
            description: this.$t('web.anonymity'),
          },
          {
            img: {
              src: require('@/assets/image/apps/icon-5.png'),
            },
            description: this.$t('web.simple_conf'),
          },
          {
            img: {
              src: require('@/assets/image/apps/icon-3.png'),
            },
            description: this.$t('web.secure'),
          },
          {
            img: {
              src: require('@/assets/image/apps/icon-6.png'),
            },
            description: this.$t('web.protection'),
          },
        ]
      },
    },
  },
  methods: {
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = 'default'
      if (macosPlatforms.includes(platform)) {
        os = PLATFORM.MACOS
      } else if (iosPlatforms.includes(platform)) {
        os = PLATFORM.IOS
      } else if (windowsPlatforms.includes(platform)) {
        os = PLATFORM.WINDOWS
      } else if (/Android/.test(userAgent)) {
        os = PLATFORM.ANDROID
      } else if (/Linux/.test(platform)) {
        os = PLATFORM.LINUX
      }
      return os
    },
  },
}
</script>

<style lang="scss">
@import 'index';
</style>
