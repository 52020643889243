<template>
  <div class="product__action c-macos">
    <div class="product__action-content">
      <c-link
        :to="link"
        external
        nowrap
        underline
        class="text text-sm"
        :label="$t('web.instructions_for_linux')"
      />
    </div>
  </div>
</template>

<script>
import CLink from '@/components/Link'
export default {
  name: 'Linux',
  components: {
    CLink,
  },
  data() {
    this.link = process.env.VUE_APP__KNOWLEDGE_BASE_URL
  },
}
</script>
