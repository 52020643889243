<template>
  <div class="product__action c-android">
    <div class="product__action-content">
      <slot-link class="mx-1" :to="iosLink" external height="48">
        <img
          :src="require('@/assets/image/store/appstore-ios.png')"
          alt="Download on the App Store"
        />
      </slot-link>
      <slot-link class="mx-1" :to="androidLink" external height="48">
        <img
          :src="require('@/assets/image/store/googleplay.png')"
          alt="Download on the App Store"
        />
      </slot-link>
    </div>
  </div>
</template>

<script>
import SlotLink from '@/components/SlotLink'
export default {
  name: 'DefaultComponent',
  components: {
    SlotLink,
  },
  data() {
    this.androidLink = process.env.VUE_APP__ANDROID_APP_URL
    this.iosLink = process.env.VUE_APP__IOS_APP_URL
  },
}
</script>
