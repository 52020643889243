<template>
  <section class="section section--devices">
    <div class="devices">
      <h2
        class="
          devices__title
          title title-lg
          text-center
          color-main-opposite
          mb-3
        "
      >
        {{
          $t(`web.different_device`, {
            productName: PRODUCT_NAME,
          })
        }}
      </h2>
      <div class="no-style devices__list">
        <div v-for="(item, index) in apps" :key="index" class="devices__item">
          <link-button
            :to="item.link.href"
            :value="item.label"
            size="md"
            color="white"
            transparent
            external
            compressed
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { APPS } from '@/CONSTANTS'
import LinkButton from '@/components/LinkButton'
export default {
  name: 'AppsSection',
  components: {
    LinkButton,
  },
  data() {
    this.PRODUCT_NAME = process.env.VUE_APP__PRODUCT_NAME
  },
  computed: {
    apps: {
      get() {
        return (
          this.$store.getters['apps/list']
            ?.filter((app) => app.device !== APPS.DEVICE_TYPE.OTHER)
            .sort((a, b) => (a.device > b.device ? -1 : 1)) || []
        )
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
