<template>
  <div class="product__action c-macos">
    <div class="product__action-content">
      <slot-link :to="link" external>
        <img
          :src="require('@/assets/image/store/appstore-mac.png')"
          :alt="$t('web.instructions_for_macos')"
          height="48"
        />
      </slot-link>
    </div>
  </div>
</template>

<script>
import SlotLink from '@/components/SlotLink'
export default {
  name: 'Macos',
  components: {
    SlotLink,
  },
  data() {
    this.link = process.env.VUE_APP__MACOS_APP_URL
  },
}
</script>
