<template>
  <div class="product__action c-android">
    <div class="product__action-content">
      <slot-link :to="link" external height="48">
        <img
          :src="require('@/assets/image/store/googleplay.png')"
          alt="Download on the Google Play"
        />
      </slot-link>
    </div>
  </div>
</template>

<script>
import SlotLink from '@/components/SlotLink'
export default {
  name: 'Android',
  components: {
    SlotLink,
  },
  data() {
    this.link = process.env.VUE_APP__ANDROID_APP_URL
  },
}
</script>
