<template>
  <section class="section section--platform section-platform">
    <div class="section-platform__wrapper">
      <div class="section-platform__content">
        <div class="product">
          <div
            class="product__preview text-center"
            :class="`product__preview--${currentPlatform.name}`"
          >
            <img
              :src="currentPlatform.mainImg.src"
              :alt="currentPlatform.mainImg.alt"
              class="product-new__preview-img"
            />
          </div>
          <div
            class="product__content"
            :class="`product__content--${currentPlatform.name}`"
          >
            <div class="product__title">
              <h1 class="title title-lg">
                {{
                  $t(`web.get_VPN_app_phone`, {
                    productName: $store.state.app.productName,
                  })
                }}
              </h1>
            </div>
            <div class="product__description">
              <p class="text text-md color-grey">
                {{ $t('web.keep_safe') }}
              </p>
            </div>
            <android-actions
              v-if="toShowPlatform($CONSTANTS.PLATFORM.ANDROID)"
            ></android-actions>
            <ios-actions
              v-else-if="toShowPlatform($CONSTANTS.PLATFORM.IOS)"
            ></ios-actions>
            <windows-actions
              v-else-if="toShowPlatform($CONSTANTS.PLATFORM.WINDOWS)"
            ></windows-actions>
            <macos-actions
              v-else-if="toShowPlatform($CONSTANTS.PLATFORM.MACOS)"
            ></macos-actions>
            <linux-actions
              v-else-if="toShowPlatform($CONSTANTS.PLATFORM.LINUX)"
            ></linux-actions>
            <default-component
              v-else-if="toShowPlatform('default')"
            ></default-component>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AndroidActions from './includes/Android'
import WindowsActions from './includes/Windows'
import IosActions from './includes/Ios'
import MacosActions from './includes/Macos'
import LinuxActions from './includes/Linux'
import DefaultComponent from './includes/Default'
import { PLATFORM, ENV } from '@/CONSTANTS'

export default {
  name: 'appsPlatform',

  components: {
    AndroidActions,
    WindowsActions,
    IosActions,
    MacosActions,
    LinuxActions,
    DefaultComponent,
  },

  props: {
    platform: {
      type: String,
      required: true,
    },
  },

  computed: {
    platformData: {
      get() {
        const res = [
          {
            name: 'default',
            mainImg: {
              src: require('@/assets/image/devices/iphone.png'),
              alt: 'iPhone',
            },
          },
        ]
        ENV.ANDROID_APP_URL &&
          res.push({
            name: PLATFORM.ANDROID,
            mainImg: {
              src: require('@/assets/image/devices/android.png'),
              alt: 'Phone on Android',
            },
          })
        ENV.IOS_APP_URL &&
          res.push({
            name: PLATFORM.IOS,
            mainImg: {
              src: require('@/assets/image/devices/iphone.png'),
              alt: 'iPhone',
            },
          })
        ;(ENV.WINDOWS_APP_URL || ENV.KNOWLEDGE_BASE_URL) &&
          res.push({
            name: PLATFORM.WINDOWS,
            mainImg: {
              src: require('@/assets/image/devices/macbook.png'),
              alt: 'MacBook',
            },
          })
        ;(ENV.MACOS_APP_URL || ENV.KNOWLEDGE_BASE_URL) &&
          res.push({
            name: PLATFORM.MACOS,
            mainImg: {
              src: require('@/assets/image/devices/macbook.png'),
              alt: 'MacBook',
            },
          })
        ;(ENV.LINUX_APP_URL || ENV.KNOWLEDGE_BASE_URL) &&
          res.push({
            name: PLATFORM.LINUX,
            mainImg: {
              src: require('@/assets/image/devices/macbook.png'),
              alt: 'MacBook',
            },
          })
        return res
      },
    },
    currentPlatform: {
      get() {
        let platform = this.platformData.find(
          (item) => item.name === this.platform
        )
        if (!platform) {
          platform = this.platformData.find((item) => item.name === 'default')
        }
        return platform
      },
    },
  },

  methods: {
    toShowPlatform(platformName) {
      return this.currentPlatform.name === platformName
    },
  },
}
</script>
