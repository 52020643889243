<template>
  <div class="product__action c-macos">
    <div class="product__action-content">
      <slot-link :to="link" external>
        <img
          :src="require('@/assets/image/store/store-windows.png')"
          :alt="$t('web.instructions_for_windows')"
          height="48"
        />
      </slot-link>
    </div>
  </div>
</template>

<script>
import SlotLink from '@/components/SlotLink'
export default {
  name: 'Windows',
  components: {
    SlotLink,
  },
  data() {
    this.link = 'https://trustvpn.getvpngn.com/win'
  },
}
</script>
